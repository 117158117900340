<template>
  <items-data-table-component
      endpoint="ideas"
      type="ideas"
      :routes="routes"/>
</template>
<script>

export default {
  name: "Ideas",
  data () {
    return {
      routes: {
        create: 'dashboard.ideas.create',
        show: 'dashboard.ideas.show',
        edit: 'dashboard.ideas.edit'
      }
    }
  }
}
</script>